<template>
    <div class="px-3 py-2">
        <v-form ref="forinv" lazy-validation>
            <b-sidebar id="add_part_invoice" aria-labelledby="sidebar-no-header-title" no-header backdrop width="99%" :style="`direction:`+lang.ldir" right :title="lang.add_direct_invoice" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.add_invoice}}</span>
                        </div>
                        <div @click="hide" id="HideInvoice" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                    <v-divider />
                    <div class="px-3 py-2" style="direction:rtl">
                        <div v-if="customer.id == 0 && $store.state.licenseType.showCustomerTypes" class="tobbuttom backBlack" :style="`direction:${lang.dir};padding:7px;width:100%;text-align:center;`">
                            <b-button :style="`border-radius:0;background:transparent;color:#000;border:none;`">{{lang.customer_type}}</b-button>
                            <b-button style="margin-inline-end:5px;font-weight: bolder;width:150px;font-size:1.1rem !important;margin-inline-end:5px;border:2px solid #fff !important" id="_cInd" :class="`dispFlex btn btn-small redColor borderRad_`+lang.algin" @click="changeCusType(0)" >{{lang.individual_client}} <i id="_indiv"  style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down"></i></b-button>
                            <b-button style="margin-inline-end:5px;font-weight: bolder;width:150px;font-size:1.1rem !important;border:2px solid #fff !important" id="_cCom" :class="`dispFlex btn btn-small weightColor borderRad_none`" @click="changeCusType(1)" >{{lang.company_clinet}} <i id="_comps"  style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down hideme"></i></b-button>
                            <b-button style="margin-inline-end:5px;font-weight: bolder;width:150px;font-size:1.1rem !important;border:2px solid #fff !important" id="_cGov" :class="`dispFlex btn btn-small weightColor borderRad_`+lang.lalgin" @click="changeCusType(2)" >{{lang.government_clinet}} <i id="_goves" style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down hideme"></i></b-button>
                        </div>
                        <div class="invoiceSettings">
                            <div class="setItem">
                                <label>{{lang.invoice_type}}</label>
                                <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        v-model="invoice.invtype"
                                        :options="invtypes"
                                        @change="InvType()"
                                        style="background:green !important;width:100%"
                                        />
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div class="setItem">
                                <label>{{lang.vat_type}}</label>
                                <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        v-model="invoice.vtype"
                                        :options="stOption.vattypes"
                                        @change="InvType()"
                                        style="background:green !important;width:100%;"
                                        />
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div class="setItem">
                                <label>{{lang.payment_method}}</label>
                                <b-input-group>
                                <b-form-select
                                    class="selborder"
                                    v-model="invoice.paytype"
                                    :options="OpPayTypes"
                                    style="width:100%"
                                    ></b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div class = "setItem">
                                <label>{{lang.saler_name}}</label>
                                <b-input-group>
                                <b-form-select
                                    class="selborder"
                                    v-model="invoice.userid"
                                    :options="users"
                                    style="width:100%"
                                    ></b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div class="setItem" v-if="$store.state.caneditinvoicedate">
                                <label>{{lang.invoice_date}}</label>
                                <div class="flexTay">
                                    <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="sd.day" :options="$store.state.daysList"/>
                                    <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="sd.month" :options="$store.state.monthsList" />
                                    <b-form-select class="inborder text-center" style="width:60%;background:#efefef;" v-model="sd.year" :options="$store.state.yearList" />
                                </div>
                            </div>
                            <div class="setItem" v-else>
                                <label>{{lang.invoice_date}}</label>
                                <b-form-input
                                    class="inborder"
                                        v-model="invoice.date"
                                        :label="lang.invoice_date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                    />
                            </div>
                            <div class="setItem">
                                <label>{{lang.chose_store}}</label>
                                <b-input-group>
                                <b-form-select
                                    class="selborder"
                                    v-model="invoice.store_id"
                                    :options="storeIds"
                                    ref="store_id"
                                     style="width:100%"
                                    ></b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div class="setItem">
                                <label>عمولة المندوب</label>
                                <b-form-input
                                    class="inborder"
                                        v-model="agent_comm"
                                    />
                            </div>
                            <div class="setItem">
                                <label>المبلغ المدفوع</label>
                                <b-form-input
                                    class="inborder"
                                        v-model="a_discount"
                                    />
                            </div>
                            <div class="setItem">
                                <label>اظهار رقم الصنف</label>
                                <b-input-group>
                                <b-form-select
                                    class="selborder"
                                    v-model="invoice.show_item_code"
                                    :options="$store.state[`yesNoList_${lang.langname}`]"
                    
                                     style="width:100%"
                                    ></b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </div>
                        <div class="customerInfo">
                            <div class="cusItem" v-if="$store.state.licenseType.showIDNumber">
                                <label>{{ lang.customerid }}</label>
                                <b-form-input class="inborder" v-model="customer.id" @change="getAllName(customer.id)" />
                            </div>
                            <div class="cusItem">
                                <label>{{ lang.mobile }}</label>
                                <b-form-input class="inborder" v-model="customer.mobile" @change="getAllName(customer.mobile)" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 1">
                                <label>{{ lang.customer_name }}</label>
                                <b-form-input class="inborder" v-model="customer.full_name" @change="getAllName(customer.full_name)" />
                            </div>
                            <div class="custComName" v-if="customer.customer_type == 2">
                                <label>{{ lang.company_name }}</label>
                                <b-form-input class="inborder" v-model="customer.company_name" @change="getAllName(customer.company_name)" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 2">
                                <label>{{ lang.vat_number }}</label>
                                <b-form-input class="inborder" v-model="customer.company_vatid" @change="getAllName(customer.company_vatid)" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 2">
                                <label>{{ lang.crt_number }}</label>
                                <b-form-input class="inborder" v-model="customer.crt_number" @change="getAllName(customer.crt_number)" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 2">
                                <label>{{ lang.building_no }}</label>
                                <b-form-input class="inborder" v-model="customer.building_no" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 2">
                                <label>{{ lang.street_name }}</label>
                                <b-form-input class="inborder" v-model="customer.street_name" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 2">
                                <label>{{ lang.branch_number }}</label>
                                <b-form-input class="inborder" v-model="customer.branch_number" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 2">
                                <label>{{ lang.district }}</label>
                                <b-form-input class="inborder" v-model="customer.district" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 2">
                                <label>{{ lang.zipcode }}</label>
                                <b-form-input class="inborder" v-model="customer.zipcode" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 2">
                                <label>{{ lang.city }}</label>
                                <b-form-input class="inborder" v-model="customer.city" />
                            </div>
                            <div class="cusAdrItem" v-if="customer.customer_type == 2">
                                <label>{{ lang.address }}</label>
                                <b-form-input class="inborder" v-model="customer.address" />
                            </div>
                            <div class="custComName" v-if="customer.customer_type == 3">
                                <label>{{ lang.entity_name }}</label>
                                <b-form-input class="inborder" v-model="customer.entity_name" />
                            </div>
                            <div class="custComName" v-if="customer.customer_type == 3">
                                <label>{{ lang.branch_name }}</label>
                                <b-form-input class="inborder" v-model="customer.branch_name" />
                            </div>
                            <div class="custComName" v-if="customer.customer_type == 3">
                                <label>{{ lang.branch_name }}</label>
                                <b-form-input class="inborder" v-model="customer.branch_name" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 3">
                                <label>{{ lang.tin_number }}</label>
                                <b-form-input class="inborder" v-model="customer.tin_number" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 3">
                                <label>{{ lang.po_number }}</label>
                                <b-form-input class="inborder" v-model="invoice.po_number" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 3">
                                <label>{{ lang.po_date }}</label>
                                <b-form-input class="inborder" type="date" v-model="invoice.po_date" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 3">
                                <label>{{ lang.request_number }}</label>
                                <b-form-input class="inborder" v-model="invoice.request_number" />
                            </div>
                            <div class="cusItem" v-if="customer.customer_type == 3">
                                <label>{{ lang.order_number }}</label>
                                <b-form-input class="inborder" v-model="invoice.order_number" />
                            </div>
                        </div>
                    </div>
                    <v-row :style="`padding:5px;direction:${lang.dir}`">
                        <v-col cols="12" md="6">
                            <div style="height:400px;overflow: auto;margin-inline-end: 15px;">
                                <div style="background:black;padding:5px;text-align: center;color:#fff;">البحث عن صنف</div>
                                <div class="invoiceSettings" style="margin:5px;">
                                    {{ lang.search_item_code_item_name }}  <b-form-input class="inborder" @change="getNProducts()" @keyup.enter="getNProducts()" style="width:300px;margin-inline:5px;direction: ltr;" v-model="item_search" />
                                    <b-form-select class="selborder" v-model="search_type" :options="stOption.searchTypes" style="width:300px;margin-inline:5px;" />
                                </div> 
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="backBlack text-center">#</th>
                                            <th class="backBlack text-center">{{ lang.item_code }}</th>
                                            <th class="backBlack text-center">{{ lang.item_name }}</th>
                                            <th class="backBlack text-center">{{ lang.brand }}</th>
                                            <th class="backBlack text-center">{{ lang.location }}</th>
                                            <th class="backBlack text-center">{{ lang.cost_price }}</th>
                                            <th class="backBlack text-center">{{ lang.price }}</th>
                                            <th class="backBlack text-center">{{ lang.last_price }}</th>
                                            <th class="backBlack text-center">{{ lang.current_qty }}</th>
                                            <th class="backBlack text-center">{{ lang.unit_name }}</th>
                                            <th class="backBlue text-center" colspan="2">{{ lang.action }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in item_rows" :key="index">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td class="text-center" style="direction:ltr">{{ item.item_code }}</td>
                                            <td class="text-center">{{ item[`item_name${lang.langname}`] }}</td>
                                            <td class="text-center">{{ item[`brands_${lang.langname}`] }}</td>
                                            <td class="text-center">{{ item[`location_${lang.langname}`] }}</td>
                                            <td class="text-center">{{ item.costs }}</td>
                                            <td class="text-center">{{ item.default_price }}</td>
                                            <td class="text-center">{{ item.last_invoice }}</td>
                                            <td class="text-center">{{ item[`unit_name${lang.langname}`] }}</td>
                                            <td class="text-center backBlue" style="cursor: pointer;" @click="addToItems(index)">
                                                {{ lang.add }}
                                            </td>
                                            <td class="text-center backGreen" style="cursor: pointer;"  @click="getTrack(item.item_code)">
                                                حركة صنف
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </div>
                            <div style="height:200px;overflow: auto;margin-inline-end: 15px;">
                                <div style="background:black;padding:5px;text-align: center;color:#fff;">حركة صنف</div>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="backBlack text-center">#</th>
                                            <th class="backBlack text-center" style="direction:ltr">{{ lang.item_code }}</th>
                                            <th class="backBlack text-center">{{ lang.item_name }}</th>
                                            <th class="backBlack text-center">{{ lang.qtty }}</th>
                                            <th class="backBlack text-center">{{ lang.item_price }}</th>
                                            <th class="backBlack text-center">{{ lang.discount }}</th>
                                            <th class="backBlack text-center">{{ lang.total }}</th>
                                            <th class="backBlack text-center">{{ lang.customer_name }}</th>
                                            <th class="backBlack text-center">{{ lang.mobile }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in itemTrak" :key="index">
                                            <td class="text-center">{{ item.item_code }}</td>
                                            <td class="text-center">{{ item.item_name }}</td>
                                            <td class="text-center">{{ item.qty }}</td>
                                            <td class="text-center">{{ item.item_price }}</td>
                                            <td class="text-center">{{ item.discount }}</td>
                                            <td class="text-center">{{ item.total }}</td>
                                            <td class="text-center">{{ item.vat }}</td>
                                            <td class="text-center">{{ item.ftotal }}</td>
                                            <td class="text-center">{{ item.customer_name }}</td>
                                            <td class="text-center">{{ item.mobile }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div style="height:400px;overflow: auto;margin-inline-end: 15px;">
                                <div style="background:black;padding:5px;text-align: center;color:#fff;">أصناف الفاتورة</div>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="backBlack text-center">#</th>
                                            <th class="backBlack text-center">{{ lang.item_code }}</th>
                                            <th class="backBlack text-center">{{ lang.item_name }}</th>
                                            <th class="backBlack text-center">{{ lang.qtty }}</th>
                                            <th class="backBlack text-center">{{ lang.item_price }}</th>
                                            <th class="backBlack text-center">{{ lang.discount }}</th>
                                            <th class="backBlack text-center">{{ lang.total }}</th>
                                            <th class="backBlack text-center">{{ lang.vat }}</th>
                                            <th class="backBlack text-center">{{ lang.ftotal }}</th>
                                            <th class="backBlue text-center" colspan="2">{{ lang.action }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in invoice.rows" :key="index" @keyup.left="doItIndex(index)" :ref="`invRows_${index}`">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td class="text-center" style="direction:ltr">{{ item.item_code }}</td>
                                            <td class="text-center">{{ item.item_name }}</td>
                                            <td class="text-center">{{ item.qty }}</td>
                                            <td class="text-center">{{ item.item_price }}</td>
                                            <td class="text-center">{{ item.discount }}</td>
                                            <td class="text-center">{{ item.total }}</td>
                                            <td class="text-center">{{ item.vat }}</td>
                                            <td class="text-center">{{ item.ftotal }}</td>
                                            <td class="text-center backBlue" style="cursor: pointer;" @click="updateThis(index)">{{ lang.update }}</td>
                                            <td class="text-center backRed" style="cursor: pointer;" @click="deleteThis(index)">{{ lang.delete }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </div>
                            <div style="height:200px;overflow: auto;margin-inline-end: 15px;">
                                <div style="background:black;padding:5px;text-align: center;color:#fff;">تعديل صنف</div>
                                <v-row>
                                    <v-col cols="12" md="3">
                                        <label>{{ lang.item_name }}</label>
                                        <b-form-input class="inborder" v-model="updateRow.item_name" />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <label>{{ lang.item_price }}</label>
                                        <b-form-input class="inborder" @change="reCalck()" v-model="updateRow.item_price" />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <label>{{ lang.qtty }}</label>
                                        <b-form-input class="inborder" @change="reCalck()" v-model="updateRow.qty" />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <label>{{ lang.discount }}</label>
                                        <b-form-input class="inborder" @change="reCalck()" v-model="updateRow.discount" />
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <label>{{ lang.discount_type }}</label>
                                        <b-form-select class="selborder" @change="reCalck()" v-model="updateRow.discount_type" :options="stOption.discountTypes" />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <label>{{ lang.total }}</label>
                                        <b-form-input class="inborder" v-model="updateRow.total" readonly />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <label>{{ lang.vat }}</label>
                                        <b-form-input class="inborder" v-model="updateRow.vat" readonly />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <label>{{ lang.ftotal }}</label>
                                        <b-form-input class="inborder" v-model="updateRow.ftotal" readonly />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <label></label>
                                        <div><b-button @click="saveItem()" style="background:green;font-size:0.8rem;">{{ lang.save }}</b-button></div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-5">
                        <b-button type="button" @click="addInvoicex()" v-if="$store.state.licenseType.showApprove" v-b-toggle.invoiceApprove id="addBTN" variant="success" class="ma-2 py-3 btn-sm" style="width:170px;font-size:1.3rem !important;">{{$parent.lang.save_n_print}}</b-button>
                        <b-button type="button" @click="addInvoice()" v-else id="addBTN" variant="success" class="ma-2 py-3 btn-sm" style="width:170px;font-size:1.3rem !important;">{{$parent.lang.save_n_print}}</b-button>
                        <b-button type="button" v-b-modal.add_addporch id="addModalBTN" variant="success" class="ma-2 py-3 btn-sm" style="display:none;width:170px;font-size:1.3rem !important;">{{$parent.lang.save_n_print}}</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default {
    compoenets: {},
    data() {
        return {
            a_discount: '',
            agent_comm: '',
            percentage: '',
            discount: 0,
            ftotal: '',
            rowDiscount: 0,
            selectedItem: null,
            updateRow: {
                item_name: '',
                qty: '',
                item_price: '',
                discount: '',
                total: '',
                vat: '',
                ftotal: '',
                discount_type: 1,
            },
            newInvoiceID: '',
            item_search: '',
            item_rows: [],
            search_type: 2,
            customer: {
                id: '',
                mobile: '',
                cctype: 1,
                customer_type: 1,
                full_name: '',
                company_name: '',
                company_vatid: '',
                crt_number: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city: '',
                address: '',
                entity_name: '',

            },
            invoice: {
                invtype: 1,
                vtype: 2,
                paytype: 1,
                userid: 1,
                date: '',
                store_id: 1,
                po_number: '',
                po_date: '',
                request_number: '',
                order_number: '',
                rows: [],
                show_item_code: 1,
            },
            invoiceTypes: [
                {name_ar: 'فاتورة نقدية', name_en: 'Debit Invoice', value: 1},
                {name_ar: 'فاتورة آجلة', name_en: 'Cridet Invoice', value: 2},
            ],
            staticOptions: [],
            payTypes: [],
            userList: [],
            sd: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            stores: [],
            itemTrak: []
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        stOption: function()
        {
            let t = {}
            for(let i=0;i<this.staticOptions.length;i++){
                if (t && t[this.staticOptions[i].option_phrase]) {
                    t[this.staticOptions[i].option_phrase].push({value: this.staticOptions[i].value, text: this.staticOptions[i][`name_${this.lang.langname}`]})
                }
                else{
                    t[this.staticOptions[i].option_phrase] = []
                    t[this.staticOptions[i].option_phrase].push({value: this.staticOptions[i].value, text: this.staticOptions[i][`name_${this.lang.langname}`]})
                }
            }
            console.log("t is t" , t)
            return t;
        },
        OpPayTypes: function()
        {
            let t = []
            let name_ar = ''
            let name_en = ''
            let name = ''
            for(let i=0;i<this.payTypes.length;i++){
                name_ar = this.payTypes[i].type_name;
                name_en = this.payTypes[i].type_name_en;
                if(this.lang.langname == 'ar') name = name_ar
                else name = name_en
                t.push({text: name, value: this.payTypes[i].id})
            }
            return t;
        },
        invtypes: function()
        {
            let t = []
            for(let i=0;i<this.invoiceTypes.length;i++){
                t.push({text: this.invoiceTypes[i][`name_${this.lang.langname}`], value: this.invoiceTypes[i].value})
            }
            return t;
        },
        storeIds: function()
        {
            let t = []
            for(let i=0;i<this.stores.length;i++){
                t.push({text: this.stores[i][`name_${this.lang.langname}`], value: this.stores[i].id})
            }
            return t;
        },
        users: function()
        {
            let t = []
            for(let i=0;i<this.userList.length;i++){
                t.push({text: this.userList[i].full_name, value: this.userList[i].id})
            }
            return t;
        },
        
    },
    methods: {
        makeDiscount(){
            if(this.a_discount == 0 || this.a_discount == ''){
                this.discount = 0;
                for(let i=0;i<this.tbrows.length;i++){
                    this.tbrows[i].discount = 0;
                }
                this.changeVatType();
                return false;
            }
            
            // alert(this.discount)
            // const post = new FormData();
            // post.append("type","doDiscount");
            // post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            // post.append('data[discount]',this.discount);
            // post.append('data[tbRows]',JSON.stringify(this.tbrows));
            // axios.post(
            //     this.$store.state.SAMCOTEC.r_path, post
            // ).then((response) => {
            //     this.tbrows = response.data.results.data;
            //     this.changeVatType();
            // });
            
            const discount = parseFloat(this.nsp.ftotal) - parseFloat(this.a_discount);
            // alert([discount,this.nsp.ftotal,this.a_discount]);
            let per = parseFloat(discount) / this.nsp.ftotal;
                // alert(per)
            let disc = 0;
            this.discount = this.a_discount;
            for(let i=0;i<this.tbrows.length;i++){
                disc = parseFloat(this.tbrows[i].total) * per
                // alert([per, disc])
                this.tbrows[i].discount = this.$RoundNum(parseFloat(this.tbrows[i].discount) + disc , 4);
            }
            this.changeVatType();

        },
        deleteThis(index){
            this.invoice.rows.splice(index, 1)
        },
        saveItem()
        {
            this.invoice.rows[this.selectedItem].item_name = this.updateRow.item_name;
            this.invoice.rows[this.selectedItem].item_price = this.updateRow.item_price;
            this.invoice.rows[this.selectedItem].qty = this.updateRow.qty;
            this.invoice.rows[this.selectedItem].discount = this.$RoundNums(this.updateRow.item_price - this.rowDiscount , 4);
            this.invoice.rows[this.selectedItem].total = this.updateRow.total; 
            this.invoice.rows[this.selectedItem].vat = this.updateRow.vat;
            this.invoice.rows[this.selectedItem].ftotal = this.updateRow.ftotal;
            this.selectedItem = null,
            this.rowDiscount = 0
            this.updateRow = {
                item_name: '',
                qty: '',
                item_price: '',
                discount: '',
                total: '',
                vat: '',
                ftotal: '',
                discount_type: 1,
            }
        },
        reCalck() {
            if(this.updateRow.qty == '' || isNaN(this.updateRow.qty)) this.updateRow.qty = 1;
            if(this.updateRow.item_price == '' || isNaN(this.updateRow.item_price)) this.updateRow.item_price = 0;
            if(this.updateRow.discount == '' || isNaN(this.updateRow.discount)) this.updateRow.discount = 0;
            let item_price = this.updateRow.item_price;
            let discount = 0;
            if(this.updateRow.discount_type == 1){
                item_price = this.$RoundNums(parseFloat(item_price) - parseFloat(this.updateRow.discount),4);
            }
            else if(this.updateRow.discount_type == 2){
                discount = this.$RoundNums((parseFloat(this.updateRow.item_price) * parseFloat(this.updateRow.discount)) / 100, 4);
                item_price = parseFloat(item_price) - parseFloat(discount);
            }
            let total = this.$RoundNums(parseFloat(item_price) * this.updateRow.qty, 4)
            if(this.updateRow.discount_type == 3){
                total = this.$RoundNums(parseFloat(total) - parseFloat(this.updateRow.discount),4);
            }
            else if(this.updateRow.discount_type == 4){
                discount = this.$RoundNums((parseFloat(total) * parseFloat(this.updateRow.discount)) / 100, 4);
                total = parseFloat(total) - parseFloat(discount);
            }
            const c = this.$calcVat(total, this.invoice.vtype)
            this.updateRow.total = c.tot;
            this.updateRow.vat = c.vat;
            this.updateRow.ftotal = c.ftot;
            this.rowDiscount = this.$RoundNums(this.updateRow.total / this.updateRow.qty, 4)
        },
        updateThis(index)
        {
            this.updateRow = {
                item_name: this.invoice.rows[index].item_name,
                qty: this.invoice.rows[index].qty,
                item_price: this.invoice.rows[index].item_price,
                discount: this.invoice.rows[index].discount,
                discount_type: 1,
                total: this.invoice.rows[index].total,
                vat: this.invoice.rows[index].vat,
                ftotal: this.invoice.rows[index].ftotal,
            }
            this.selectedItem = index;
        },
        addToItems(index){
            let addItem = true;
            for(let i=0;i<this.invoice.rows.length;i++){
                if(this.invoice.rows[i].item_code == this.item_rows[index].item_code && this.invoice.rows[i].item_name == this.item_rows[index][`item_name${this.lang.langname}`]){
                    addItem = false;
                }
            }
            if(addItem){
                const _vat = this.$calcVat(this.item_rows[index].default_price, this.invoice.vtype)
                const t = {
                    item_code: this.item_rows[index].item_code,
                    qty: 1,
                    item_name: this.item_rows[index][`item_name${this.lang.langname}`],
                    item_price: this.$RoundNums(this.item_rows[index].default_price,4),
                    discount: 0,
                    total: this.$RoundNums(_vat.tot,4),
                    vat: this.$RoundNums(_vat.vat,4),
                    ftotal: this.$RoundNums(_vat.ftot,4),
                };
                this.invoice.rows.push(t);
            }else{
                let message = "لقد قمت مسبقا باضافة هذا الصنف لتغيير الكمية يرجى تعديل الصنف";
                this.invoice.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            
        },
        makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
            }
            return result;
        },
        getNewInvoiceID(){
            this.newInvoiceID = this.makeid(10)
        },
        reset()
        {
            this.newInvoiceID = '';
            this.item_search = '';
            this.search_type = 2;
            this.item_rows = [];
            this.getSaticOptions();
            this.sd = {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
        },
        changeCusType(id){
            
            if(id == 0){
                this.customer.cctype = 1;
                this.customer.customer_type = 1;
                this.showcamp = false;
                this.showGov = false;
                document.getElementById('_indiv').classList.remove('hideme');
                document.getElementById('_comps').classList.add('hideme');
                document.getElementById('_goves').classList.add('hideme');

                document.getElementById('_cInd').classList.remove('redColor');
                document.getElementById('_cCom').classList.remove('redColor');
                document.getElementById('_cGov').classList.remove('redColor');
                document.getElementById('_cInd').classList.remove('weightColor');
                document.getElementById('_cCom').classList.remove('weightColor');
                document.getElementById('_cGov').classList.remove('weightColor');

                document.getElementById('_cInd').classList.add('redColor');
                document.getElementById('_cCom').classList.add('weightColor');
                document.getElementById('_cGov').classList.add('weightColor');

            }else if(id == 1){
                this.customer.cctype = 2;
                this.customer.customer_type = 2;
                this.showcamp = true;
                this.showGov = false;
                document.getElementById('_comps').classList.remove('hideme');
                document.getElementById('_indiv').classList.add('hideme');
                document.getElementById('_goves').classList.add('hideme');

                document.getElementById('_cInd').classList.remove('redColor');
                document.getElementById('_cCom').classList.remove('redColor');
                document.getElementById('_cGov').classList.remove('redColor');
                document.getElementById('_cInd').classList.remove('weightColor');
                document.getElementById('_cCom').classList.remove('weightColor');
                document.getElementById('_cGov').classList.remove('weightColor');

                document.getElementById('_cInd').classList.add('weightColor');
                document.getElementById('_cCom').classList.add('redColor');
                document.getElementById('_cGov').classList.add('weightColor');

            }
            else if(id == 2){
                this.customer.cctype = 3;
                this.customer.customer_type = 3;
                this.showGov = true;
                this.showcamp = false;
                document.getElementById('_goves').classList.remove('hideme');
                document.getElementById('_comps').classList.add('hideme');
                document.getElementById('_indiv').classList.add('hideme');

                document.getElementById('_cInd').classList.remove('redColor');
                document.getElementById('_cCom').classList.remove('redColor');
                document.getElementById('_cGov').classList.remove('redColor');
                document.getElementById('_cInd').classList.remove('weightColor');
                document.getElementById('_cCom').classList.remove('weightColor');
                document.getElementById('_cGov').classList.remove('weightColor');

                document.getElementById('_cInd').classList.add('weightColor');
                document.getElementById('_cCom').classList.add('weightColor');
                document.getElementById('_cGov').classList.add('redColor');

            }
            this.customersList = [];
            this.getAllName();
            // this.changeCtype();
        },
        getAllName()
        {
            //
        },
        InvType() 
        {
            //
        },
        getStores(){
            const post = new FormData();
            post.append("type","getStores");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',-1);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.stores = response.data.results.data;
                if(this.stores.length > 0) this.invoice.store_id = this.stores[0].id;
            });
        },
        getSaticOptions()
        {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getStaticOptions')
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[phrase]','')
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data){
                    this.staticOptions = response.data.results.data
                    this.payTypes = response.data.results.paytypes  
                }
                this.getStores();
            })
            
        },
        getTrack(item_code){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','salesItemTrack');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[item_code]','item_code');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        this.itemTrak = res.results.data
                    }
                }
            );
        },
        getInvSettings() {
            if(this.$store.state.settings.vat_number == ''){
                this.invoice.vtype = 1;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        this.userList = res.results.data.users;
                        const tok = localStorage.getItem(this.$COOKIEPhase).split('||');
                        this.invoice.userid = tok[0]
                    }
                }
            );
        },
        getNProducts()
        {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getNProducts');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[search_by]',this.item_search);
            post.append('data[code_type]',this.search_type);
            post.append('data[store_id]',this.invoice.store_id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        this.item_rows = res.results.data;
                    }
                }
            );
        }
    },
    created() {
        this.getInvSettings();
    }
}
</script>


<style>
.invoiceSettings, .customerInfo{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    padding-left:10px;
    padding-right:10px;
    justify-items: center;
    align-items: center;
}
.setItem{
    width:calc(100% / 9);
    padding: 2px 3px;
}
.cusItem{
    width:calc(100% / 8);
    padding: 2px 3px;
}
.cusAdrItem{
    width:calc(100% / 3);
    padding: 2px 3px;
}
.custComName{
    width:calc(100% / 6);
    padding: 2px 3px;
}
.dispFlex{
    display:flex;
    flex-direction: row;
    justify-content: center !important;
    padding-left:10px;
    padding-right:10px;
    justify-items: center;
    align-items: center;
}
.boDry:hover{
    background:green !important;
}
.addCar{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.tobbuttom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.redColor {
    background: red!important;
    color: #fff!important;
}
.weightColor {
    background: #fff !important;
    color: #000 !important;
}
.borderRad_none, .borderRad_right {
    border-top-left-radius: 0 0!important;
    border-bottom-left-radius: 0 0!important;
    padding: 5px 10px!important;
    font-size: .8rem!important;
}
.hideme{
    display:none !important;
}
.borderRad_none {
    border-top-right-radius: 0 0!important;
    border-bottom-right-radius: 0 0!important;
}
.borderRad_left {
    border-top-left-radius: 5px 5px!important;
    border-bottom-left-radius: 5px 5px!important;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
    padding: 5px 10px!important;
    font-size: .8rem!important;
    -webkit-margin-start: 5px!important;
    margin-inline-start: 5px!important;
}
.flexTay{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-items: center;
}
.flexTay select{
    margin-inline-end: 2px;
}
</style>